.tab-container {
  width: 80%;
  margin: auto;
  padding: 20px 0px;
  background-color: #ffffff;
  box-shadow: 0 2px 48px 0 rgb(0 0 0 / 6%);
  border: 1px #ffffff solid;
}

.tab-container .custom-tabs-container .container {
  display: flex;
  position: relative;
  width: 90%;
  margin: 20px auto;
}

.tab-container .custom-tabs-container .container .block-container {
  display: flex;
  position: relative;
  width: 30%;
}

.tab-container .custom-tabs-container .container .bloc-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tab-container .custom-tabs-container .container .bloc-tabs .tabs {
  padding: 15px 20px;
  text-align: center;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
  font-size: 15px;
  font-weight: 600;
}

.displayNone {
  display: none;
}

.active-tabs {
  color: black;
  border-bottom: 1px solid transparent;
  position: relative;
  background-color: #ea75ff85 !important;
  color: #000000 !important;
}

.active-tabs::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 5px;
  background: #b627b0;
}

.content-tabs {
  width: 70%;
}

.content-tabs h2 {
  margin-top: 10px;
  font-size: 22px;
}

.content {
  width: 100%;
  height: 100%;
  display: none;
}

.active-content {
  display: block;
}

.addUser {
  width: 80%;
  margin: auto;
  padding: 20px 0px;
  border: 1px #ffffff solid;
}

input,
select,
textarea {
  border: 1px rgb(75, 75, 75) solid;
  border-radius: 5px;
  box-shadow: 0 2px 48px 0 rgb(0 0 0 / 6%);
  outline: none;
  padding: 0px 15px;
  height: 45px;
  width: 100%;
  margin: 7px 0px 0px 0px;
}

textarea {
  height: 200px;
  padding-top: 20px;
}

#avatar {
  border: none;
  box-shadow: none;
  height: fit-content;
  padding: 2px;
  margin: 20px 0px;
}

input[type="checkbox"] {
  width: 15px;
  height: auto;
  margin-right: 15px;
}

.css-19kzrtu {
  padding: 15px 30px !important;
}

form {
  display: flex;
  justify-content: center;
}

form .form-part,
form .epin-form-part,
form .payment-form-part {
  margin: 10px 0px 10px 0px;
  background-color: #ffffff;
  /* box-shadow: 0 2px 48px 0 rgb(0 0 0 / 6%); */
  border: 1px #ffffff solid;
  padding: 10px 0px;
  width: 50%;
}

form .payment-form-part {
  width: 70%;
}

form .epin-form-part {
  margin: 20px auto;
}

form .part-heading {
  color: #9c27b0;
  /* color: #f35e63; */
  font-size: 20px;
  font-weight: 500;
  margin: 0px 0px 0px 10px;
}

.Registration_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px lightgray solid;
}

.profile_edit_dialog {
  width: 100%;
}

form > .dialog-form-part > .kyc_head {
  margin: 10px 0px;
}
form > .dialog-form-part > .kyc_head > .InputContainer > .input-group {
  width: 100%;
}

form .input-group {
  width: 90%;
  margin: 20px auto;
}
form .flex-input-group {
  width: 90%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}

form .flex-input-group .first-name,
form .flex-input-group .last-name {
  width: 49%;
}

form .input-group span {
  color: #ff0000;
}

.subscription-container {
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow: scroll;
}
.subscription-container > span {
  font-size: 1.2em;
  margin: 0.8em 0.2em;
}
.subscription-container .package-card {
  margin: 10px 15px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 48px 0 rgb(0 0 0 / 6%);
  position: relative;
  padding: 10px;
  border: 1px #9c27b0 solid;
  cursor: pointer;
}

.subscription-container .deactivated-package-card {
  background-color: rgba(232, 232, 232, 0.611);
  border: 1px rgba(232, 232, 232, 0.611) solid;
}

.subscription-container .package-card span {
  display: block;
  margin: 5px 0px;
}

.subscription-container .package-card input {
  display: block;
  margin: 0px 0px 10px 0px;
}

.subscription-container .package-card .plan-type {
  font-size: 15px;
  font-weight: bold;
  display: block;
  color: #9c27b0;
  margin: 10px 0px 5px 0px;
}

.subscription-container .deactivated-package-card .plan-type {
  color: #464646;
}

.subscription-container .package-card .plan-cost {
  font-size: 20px;
  display: block;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 5%;
  color: #9c27b0;
}

.subscription-container .deactivated-package-card .plan-cost {
  color: #464646;
}

.subscription-container .package-card .details {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

.bank-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bank-section .bank {
  margin: 10px 0px;
}

.bank-section .bank .bank-name {
  font-weight: bold;
  margin: 2px 0px 8px 0px;
  color: #9c27b0;
}

.bank-section .bank .account-number,
.bank-section .bank .ifsc {
  font-weight: bold;
  margin: 5px 0px;
  color: rgb(44, 44, 44);
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .radio {
    -webkit-appearance: none;
    -webkit-appearance: but;
    -moz-appearance: none;
    background: #fff;
    border: 2px solid #9c27b0;
    padding: 2px;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    outline: none;
    transition: background 0.2s ease-out, border-color 0.2s ease-out;
    width: 20px;
    position: relative;
  }

  .radio::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 40%;
    width: 40%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: white;
    border: 1px white solid;
  }

  .radio:checked {
    background: #9c27b0;
    border-color: #9c27b0;
  }
}

/*  */
.select-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  overflow: scroll;
}

.selectable_div {
  border: 1px lightgray solid;
  padding: 10px;
  cursor: pointer;
  margin: 10px 0px;
}

.selected {
  background-color: #9c27b0;
  color: white;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .tab-container,
  .addUser {
    width: 100%;
  }

  .css-19kzrtu {
    padding: 15px 10px !important;
  }

  form {
    flex-direction: column;
  }

  form .form-part {
    width: 100%;
  }

  form label {
    font-size: 14px;
    text-transform: capitalize;
  }

  form .dialog-form-part .input-group {
    width: 100%;
  }

  .tab-container .custom-tabs-container .container {
    width: 90%;
    flex-direction: column;
  }

  .tab-container .custom-tabs-container .container .block-container {
    overflow: scroll;
    width: 100%;
  }

  .tab-container .custom-tabs-container .container .bloc-tabs {
    flex-direction: row;
  }

  .tab-container .custom-tabs-container .container .bloc-tabs .tabs {
    padding: 10px 40px;
  }

  .content-tabs {
    width: 100%;
  }

  form .payment-form-part {
    width: 100%;
  }

  form > .dialog-form-part > .inputContainer > .input-group {
    width: 100%;
  }
}
