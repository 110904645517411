.sidebar {
  width: 15%;
}

.right-container {
  width: 85%;
}

.right-container .rest {
  width: 95%;
  margin: 20px auto;
  padding: 10px;
}

.filter-btn {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 20px;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .filter-btn {
    width: 100%;
    flex-wrap: wrap;
    justify-content: stretch;
  }

  .filter-btn > button {
    margin-top: 10px;
    margin-right: 10px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .right-container {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .right-container .rest {
    width: 100%;
  }

  .rest .card-container {
    flex-wrap: wrap;
    width: 100%;
  }

  .rest .graph-container {
    flex-direction: column;
    width: 100%;
  }

  .rest .graph-container .chart {
    width: 100%;
  }

  .rest .table-container,
  .rest .data-table-container {
    flex-direction: column;
    width: 100%;
  }
}
