.nav {
  /* background-color: #000000; */
  background-color: #ffffff;
  border: 1px #ffffff solid;
  border-bottom: 1px rgb(209, 209, 209) solid;
  position: -webkit-sticky;
  position: sticky;
  top: 0%;
  z-index: 11;
}

.nav-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 95%;
  margin: 10px auto;
}

.left-side {
  display: none;
  width: 100%;
  margin: auto;
}

.left-side svg {
  height: 40px;
  width: 30px;
}

.left-side .logo {
  width: 120px;
}

.left-side .logo img {
  width: 100%;
  border-radius: 0;
}
.left-side > .__edit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.right-side {
  display: flex;
  align-items: center;
  /* color: #ffffff; */
}

.bell-icon {
  margin-left: 10px;
}

.right-side .account-name {
  display: flex;
  align-items: center;
  /* margin-left: 15px; */
  gap: 1em;
  cursor: pointer;
  position: relative;
}
.right-side > .account-name > .profileImgContainer {
  width: 2.2em;
}
/* .right-side .account-name button {
    margin-left: 10px;
} */

.mobile-nav {
  position: fixed;
  height: 100%;
  width: 220px;
  background: white;
  z-index: 99;
  transition: all 0.3s ease;
  top: 0px;
  overflow-y: scroll;
}

.mobile-nav.none {
  left: -250px;
}

.mobile-nav.show {
  left: 0px;
}

.mobile-nav .top {
  text-align: center;
  margin: 25px 0px;
}

.mobile-nav .top img {
  /* height: 100px; */
  width: 100px;
  border-radius: 0;
}

.mobile-nav .bottom ul {
  margin-top: 20px;
}
.mobile-nav .bottom ul > .treeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: none;
}
.mobile-nav .bottom ul .treeContainer > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  margin-left: 0.1em;
}
.mobile-nav .bottom ul li {
  padding: 10px;
  list-style: none;
  transition: all 0.2s ease;
  position: relative;
  margin: 15px 0px;
  cursor: pointer;
}

.mobile-nav .bottom ul li:after {
  content: "";
  position: absolute;
  background-color: #9c27b0;
  height: 100%;
  width: 2px;
  top: 0;
  left: 0%;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}

.mobile-nav .bottom ul li:hover:after {
  content: "";
  width: 100%;
  top: 0;
  right: 0;
}

.mobile-nav .bottom ul li a {
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.mobile-nav .bottom ul li:hover a {
  /* color: #000000; */
  color: white;
}

.mobile-nav .bottom ul li svg {
  text-align: center;
  width: 50px;
  font-size: 20px;
}

.logoutBtn {
  display: flex;
  padding: 0 0.5em;
  justify-content: flex-start;
}
.sidebar-overlay {
  position: fixed;
  top: 0px;
  height: 100%;
  width: 100%;
  background: rgba(90, 90, 90, 0.712);
  z-index: 98;
}

.sidebar-overlay.none {
  display: none;
}

.sidebar-overlay.show {
  display: block;
}
.pl_1em {
  padding-left: 1em !important;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .nav-wrapper {
    justify-content: space-between;
  }

  .left-side {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .right-side {
    display: none;
  }

  .mobile-nav {
    width: 250px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .mobile-nav {
    width: 230px;
  }
}
