.dashBoard_cardContainer {
  flex-wrap: wrap;
  row-gap: 0.5em;
}
@media screen and (max-width: 1200px) {
  .dashBoard_cardContainer > .card {
    /* width: 23%; */
  }
}
@media screen and (max-width: 1024px) {
  .dashBoard_cardContainer > .card {
  }
}
@media screen and (max-width: 768px) {
  .dashBoard_cardContainer > .card {
  }
}
@media screen and (max-width: 480px) {
  .dashBoard_cardContainer > .card {
  }
}
@media screen and (max-width: 320px) {
  .dashBoard_cardContainer > .card {
  }
}
