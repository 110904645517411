.wallet-container,
.wallet-container-pv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.wallet-container-pv {
  justify-content: space-between;
}

.wallet-balance {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.wallet-balance .balance-heading {
  font-weight: bold;
}

.transaction-table-flex {
  display: flex;
  justify-content: space-between;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .transaction-table-flex {
    flex-direction: column;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
}
