.invoice_component_container {
  width: 100%;
  padding: 20px;
}

.top_of_invoice {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.top_of_invoice .logo {
  height: 100px;
}

.top_of_invoice .logo img {
  border-radius: 0;
}

.mid_of_invoice {
  margin: 20px 0;
}

.invoice_component_container table,
.invoice_component_container tr,
.invoice_component_container th,
.invoice_component_container td {
  border: 1px lightgray solid;
  border-collapse: collapse;
  text-align: center;
  padding: 10px 5px;
}
table {
  width: 100%;
}

.foot_of_invoice {
  font-size: 14px;
  text-align: center;
  color: rgb(58, 58, 58);
}

.foot_of_invoice span {
  color: red;
  font-size: 16px;
}
