._wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form_container {
  width: 50%;
}

.img_container {
  height: 50px;
  width: 150px;
  margin-bottom: 20px;
}

.img_container img {
  border-radius: 0;
}

.form_style {
  width: 70%;
  border: 1px gray solid;
  border-radius: 5px;
}
