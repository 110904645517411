main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

ul {
  padding: 0;
  margin: 0%;
  list-style: none;
}

.board-container {
  display: flex;
  width: 80%;
  flex-direction: column;
  border: 1px black solid;
}

.user {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #ffe6a6;
  padding: 5px;
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

.faded_color {
  background-color: #d6d6d6;
}

.user_container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.user_container:hover .hover_container {
  display: block;
}

.hover_container {
  position: absolute;
  left: 57%;
  top: 5px;
  width: max-content;
  padding: 5px 10px;
  z-index: 11;
  border-radius: 10px;
  display: none;
  transition: all 0.5s ease-in-out;
  border: 1px black solid;
  background: #fff;
}
.user_container::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 50%;
  top: 120%;
  left: 0%;
  /* background-color: #000; */
}

.user_container::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 50%;
  top: 120%;
  left: 50%;
}

.board_seprator {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.board_seprator .board_left_seprate {
  width: 50%;
  height: 2px;
  background-color: black;
  position: relative;
}

.board_seprator .board_left_seprate::before {
  content: "";
  position: absolute;
  border-top: 2px black solid;
  border-left: 2px black solid;
  height: 10px;
  width: 10px;
  border-top-left-radius: 50px;
  left: -10px;
}

.board_seprator .board_left_seprate::after {
  content: "";
  position: absolute;
  border-bottom: 2px black solid;
  border-right: 2px black solid;
  height: 5px;
  width: 3px;
  border-bottom-right-radius: 50px;
  right: -3px;
  top: -5px;
}

.board_seprator .board_right_seprate {
  width: 49%;
  height: 2px;
  background-color: black;
  position: relative;
}

.board_seprator .board_right_seprate::before {
  content: "";
  position: absolute;
  border-top: 2px black solid;
  border-right: 2px black solid;
  height: 10px;
  width: 10px;
  border-top-right-radius: 50px;
  right: -10px;
}

.board_seprator .board_right_seprate::after {
  content: "";
  position: absolute;
  border-bottom: 2px black solid;
  border-left: 2px black solid;
  height: 5px;
  width: 3px;
  border-bottom-left-radius: 50px;
  left: -3px;
  top: -5px;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .board-container {
    width: 100%;
  }

  .user {
    height: 30px;
    width: 30px;
  }

  .thirteen_member_board .third_block .user {
    height: 40px;
    width: 40px;
  }

  .block {
    padding: 13px 0px;
  }

  .board_seprator .board_left_seprate::after {
    width: 2px;
    right: -2px;
  }

  .board_seprator .board_right_seprate::after {
    width: 2px;
    left: -2px;
  }
}
