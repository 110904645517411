.epin-request-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 20px auto;
  padding: 20px 10px;
  background-color: #ffffff;
}

.epin-request-container .epin-left-container {
  width: 50%;
}

.epin-request-container .epin-left-container .epin-img-container {
  width: 80%;
  margin: auto;
}

.epin-request-container .epin-left-container .epin-img-container img {
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: 0%;
}

.epin-request-container .epin-right-container {
  margin: auto;
}

.epin-request-container .epin-right-container .epin-button-container {
  margin: 20px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.epin-request-container .epin-right-container table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.epin-request-container .epin-right-container table td,
.epin-request-container .epin-right-container table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 10px;
  width: 200px;
}

.epin-request-container .epin-right-container table tr:nth-child(even) {
  background-color: #dddddd;
}
