.chart {
    box-shadow: 0 2px 48px 0 rgb(0 0 0 / 6%);
    padding: 10px;
    color: gray;
    /* background-color: #000000; */
    /* border: 1px white solid; */
    border-radius: 5px;
}

.lineChart {
    box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    padding: 10px;
    /* color: gray; */
}

.chart .title {
    margin-bottom: 20px;
    /* color: #ffffff; */
}

.chart .chartGrid {
    stroke: rgb(189, 189, 189);
}

.chart .recharts-cartesian-grid {
    stroke: rgb(189, 189, 189);
}