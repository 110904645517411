.genelogy-tree-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.children_line {
  width: 90%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: center; */
}

.tree_card {
  background: #fff;
  border: 1px lightgray solid;
  text-align: center;
  padding: 20px 0px;
  border-radius: 10px;
  width: 200px;
  position: relative;
}

.clickable_button {
  background: #000;
  width: 24px;
  height: 24px;
  color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -12px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.username_icon_container {
  display: flex;
  justify-content: center;
}

.tree_card .user_name {
  font-size: 15px;
}

.info-icon {
  font-size: 15px;
  cursor: pointer;
}

.other_details_container {
  position: absolute;
  left: 80%;
  top: -10%;
  border: 1px lightgray solid;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  display: none;
  z-index: 11;
  width: max-content;
}

.other_details_container > li {
  margin: 10px 0px;
}

.info_icon_container {
  margin-left: 10px;
  margin-bottom: 10px;
}

.user_name:hover .other_details_container {
  display: block;
}

.tree_card .user_username {
  font-size: 12px;
  margin: 5px 0px;
}

.tree_card .user_sponsor_id {
  font-size: 12px;
}

.children_line .tree_card {
  margin: 10px;
}

/* seprator */
.seprator {
  width: 90%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.seprator .left_seprate {
  width: 49%;
  height: 2px;
  background-color: lightgray;
  position: relative;
}

.seprator .left_seprate::before {
  content: "";
  position: absolute;
  border-top: 2px lightgray solid;
  border-left: 2px lightgray solid;
  height: 10px;
  width: 10px;
  border-top-left-radius: 50px;
  left: -10px;
}

.seprator .left_seprate::after {
  content: "";
  position: absolute;
  border-bottom: 2px lightgray solid;
  border-right: 2px lightgray solid;
  height: 10px;
  width: 10px;
  border-bottom-right-radius: 50px;
  right: -10px;
  top: -10px;
}

.seprator .right_seprate {
  width: 49%;
  height: 2px;
  background-color: lightgray;
  position: relative;
}

.seprator .right_seprate::before {
  content: "";
  position: absolute;
  border-top: 2px lightgray solid;
  border-right: 2px lightgray solid;
  height: 10px;
  width: 10px;
  border-top-right-radius: 50px;
  right: -10px;
}

.seprator .right_seprate::after {
  content: "";
  position: absolute;
  border-bottom: 2px lightgray solid;
  border-left: 2px lightgray solid;
  height: 10px;
  width: 10px;
  border-bottom-left-radius: 50px;
  left: -10px;
  top: -10px;
}

.user_line {
  margin-top: 40px;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .seprator .left_seprate {
    width: 48.6%;
  }

  .seprator .right_seprate {
    width: 48.6%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .seprator .left_seprate {
    width: 47%;
  }

  .seprator .right_seprate {
    width: 47%;
  }
}
