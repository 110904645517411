.footer {
  /* background-color: #000000; */
  background-color: #ffffff;
  /* border-top: 1px rgb(49, 49, 49) solid; */
  z-index: 11;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footer > span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  font-weight: 400;
  color: black;
}

.footer svg {
  /* margin-right: 10px; */
}
