.ticket_details-container {
  width: 100%;
}

.ticket_details-container .ticket-subject {
  width: 100%;
  text-align: center;
  margin: 20px 0px;
}

.ticket_details-container .ticket-subject .subject {
  font-weight: bold;
  font-size: 20px;
  position: relative;
}

.ticket_details-container .ticket-subject .subject::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 2.5px;
  width: 20%;
  bottom: -8px;
  background-color: lightblue;
}

.ticket_details-container .answers-area {
  width: 80%;
  margin: auto;
}

.ticket_details-container .answers-area .ticket-answer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px lightgray solid;
  padding-bottom: 10px;
  margin-top: 10px;
}

.no-answers {
  text-align: center;
}

.ticket_details-container .answers-area .reverse-answer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px lightgray solid;
  padding-bottom: 10px;
  flex-direction: row-reverse;
  margin-top: 10px;
}

.ticket_details-container .answers-area .ticket-answer .user-img-container {
  padding: 20px;
  background: #f47f7f;
  margin: 0 0px 0 20px;
  color: white;
}

.reverse-answer .user-img-container {
  padding: 20px;
  background: #f4cd7f;
  margin: 0 20px 0 0;
  color: white;
}

.user-img-container span {
  font-weight: bold;
  text-transform: capitalize;
}

.ticket_details-container .answers-area .ticket-answer .msg-container,
.ticket_details-container .answers-area .reverse-answer .msg-container {
  margin: 20px;
  text-align: justify;
}

.ticket_details-container .answers-area .msg {
  font-size: 15px;
  text-align: right;
}

.ticket_details-container .answers-area .ticket-answer .msg-date {
  font-size: 12px;
  color: gray;
  text-align: right;
  margin-top: 10px;
}

.ticket_details-container .answers-area .reverse-answer .msg-date {
  font-size: 12px;
  color: gray;
  margin-top: 10px;
}

.ticket_details-container .question-area {
  margin: 30px auto;
  border-bottom: 2px gray dashed;
  padding-bottom: 20px;
  text-align: center;
}

.ticket_details-container .question-area .question {
  font-size: 19px;
  width: 90%;
  margin: auto;
}

.ticket_details-container .reply-area {
  width: 80%;
  margin: 20px auto;
}

.ticket_details-container .reply-area .textbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ticket_details-container .reply-area .textbox-container textarea {
  height: 50px;
  margin: 0%;
  margin-left: 10px;
  padding-top: 15px;
}

.ticket_details-container .reply-area .textbox-container label {
  background: lightgray;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
}
